// Theme

const theme = {
  font: {
    primary: `'Avenir Next Normal'`,
    secondary: `'Avenir Next Medium'`,
    light: `'Avenir Next Light'`,
    normal: `'Avenir Next Normal'`,
    medium: `'Avenir Next Medium'`,
    semibold: `'Avenir Next Semibold'`,
    bold: `'Avenir Next Bold'`,
    extrabold: `'Avenir Next Extra Bold'`,
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 20px; line-height: 37px;",
    large: "font-size: 1.4em; line-height: 1.33;",
    larger: "font-size: 2.5em; line-height: 1;",
    xlarge: "font-size: 5em; line-height: 80px;",
  },

  //light-mode
  color: {
    primary: "#292B32",
    secondary: "#FA7268",
    accent: "#cca86e",
        slate150: "#292B32",

  slate200: "#424557",

  //reserved for button color
  slate300: "#EEF0FC",
  slate100:"#AFB4CA",
  slate350: "#6A7192",


  slate400: "#AFB4CA",

  slate500: "#DEE0ED",

  slate600: "#EEF0FC",

  slate700: "#F5F7FF",
    background: {
      white: "#ffffff",
      light: "#f8f8f8",
    },

  // color: {
  //   primary: "#fff",
  //   secondary: "#FA7268",
  //   accent: "#cca86e",
  //   gray: "#C1C2CF",
  //   background: {
  //     white: "#ffffff",
  //     light: "#f8f8f8",
  //   },


    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#eeeeee",
    },
    black: {
      lightest: "#8F97AF",
      light: "#B5BAC6",
      regular: "#223062",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
}

export default theme
